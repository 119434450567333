export enum NotificationType {
  FULLSCREEN_MODE_SUCCESS = "chat.navbar.notifications.toggleFullscreenSuccess",
  NEW_PASSWORD_SUCCESS = "auth.successNotifications.newPassword",
  ADD_DEPARTMENT_SUCCESS = "manager.departments.successNotifications.addDepartment",
  UPDATE_DEPARTMENT_SUCCESS = "manager.departments.successNotifications.updateDepartment",
  DELETE_DEPARTMENT_SUCCESS = "manager.departments.successNotifications.deleteDepartment",
  ADD_AGENT_SUCCESS = "manager.agents.successNotifications.addAgent",
  UPDATE_AGENT_SUCCESS = "manager.agents.successNotifications.updateAgent",
  DELETE_AGENT_SUCCESS = "manager.agents.successNotifications.deleteAgent",
  ADD_LOCATION_SUCCESS = "manager.settings.locationSettings.successNotifications.addLocation",
  UPDATE_LOCATION_SUCCESS = "manager.settings.locationSettings.successNotifications.updateLocation",
  DELETE_LOCATION_SUCCESS = "manager.settings.locationSettings.successNotifications.deleteLocation",
  UPDATE_TICKET_SUBJECT_SUCCESS = "chat.ticketSubject.feedbackMessageSuccess",
  UPDATE_TICKET_TRANSFER_REJECT_REASON_SUCCESS = "ticketTransfer.ticketTransferTable.updateTicketTransferRejectReasonSuccess",
  TICKET_CLOSED_BY_AGENT_SUCCESS = "chat.ticketCloseAction.agent",
  TICKET_CLOSED_FOR_TIMEOUT_SUCCESS = "chat.ticketCloseAction.timeout",
  API_KEY_CREATE_SUCCESS = "manager.settings.securitySettings.apiKeyForm.notifications.createApiKey.success",
  API_KEY_CREATE_FAILED = "manager.settings.securitySettings.apiKeyForm.notifications.createApiKey.error",
  API_KEY_DELETE_SUCCESS = "manager.settings.securitySettings.apiKeyForm.notifications.deleteApiKey.success",
  API_KEY_DELETE_FAILED = "manager.settings.securitySettings.apiKeyForm.notifications.deleteApiKey.error",
  API_KEY_UPDATE_SUCCESS = "manager.settings.securitySettings.apiKeyForm.notifications.updateApiKey.success",
  API_KEY_UPDATE_FAILED = "manager.settings.securitySettings.apiKeyForm.notifications.updateApiKey.error",
  CHART_DATE_RANGE_EXCEEDED = "manager.dashboard.charts.notifications.dateRangeExceeded",
  HUB_CONNECTION_LIMIT_EXCEEDED = "chat.hubConnectionLimitExceeded",
  HUB_CONNECTION_RETRY_FAILED = "chat.hubConnectionRetryFailed",
  HUB_CONNECTION_RETRY_SUCCEEDED = "chat.hubConnectionRetrySucceeded",
  HUB_CONNECTION_UNAUTHORIZED = "chat.hubConnectionUnauthorized",
  HUB_CONNECTION_FAILED = "chat.hubConnectionFailed",
  APP_CONNECTION_FAILED = "connection.offline",
  MESSAGE_SEND_FAILED = "chat.messageSendFailed",
  SET_PIN_CODE_SUCCESS = "manager.pinCode.pinCodeModal.createNewPinCodeSuccess",
  APP_USER_UNAUTHORIZED = "auth.unauthorized",
  MAX_FILE_AMOUNT_EXCEEDED = "upload.fileCountLimit",
}
